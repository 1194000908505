<template>
    <div class="activity-reports-table">
        <b-row>
            <b-col cols="12">
                <b-card no-body class="p-1">
                    <b-card-header class="p-0 position-fixed flex-column">
                        <div
                            class="d-flex w-100 justify-content-between report-print-header"
                        >
                            <div>
                                <div class="d-flex mb-2 align-items-center">
                                    <h3 class="text-primary pr-2 pl-2">
                                        <strong>MeMate</strong>
                                    </h3>
                                    <img
                                        src="/media/favicon.ico"
                                        width="32"
                                        height="32"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <b-col cols="12">
                            <hr />
                        </b-col>
                    </b-card-header>
                
                <b-card-body class="mt-2">

                
                        <ek-table
                            class="mt-1"
                            :items="statsData"
                            :columns="cols"
                            no_delete
                            no_select
                            no_pagination
                            striped
                        >
                            <template
                                slot="items.dateCreate"
                                slot-scope="{ value }"
                            >
                                {{
                                    value
                                        ? new Date(value)
                                              .toLocaleString("en-UK")
                                              .split(",")
                                              .reverse()
                                              .join(" ")
                                        : "-"
                                }}
                            </template>

                            <template
                                slot="items.accountStatuses"
                                slot-scope="{ value }"
                            >
                                <StatusBadge
                                    :statusList="accountStatus"
                                    :selectedStatusNumber="value"
                                ></StatusBadge>
                            </template>

                            <template
                                slot="items.endSub"
                                slot-scope="{ props }"
                            >
                                {{
                                    props.row.startSub && props.row.endSub
                                        ? lastSubscriptionDate(
                                              props.row.startSub,
                                              props.row.endSub
                                          )
                                        : "-"
                                }}
                            </template>

                            <template slot="items.age" slot-scope="{ value }">
                                {{ value ? value : "" }}
                            </template>

                            <template
                                slot="items.subStatuses"
                                slot-scope="{ props }"
                            >
                                <StatusBadge
                                    v-if="
                                        props.row.startSub && props.row.endSub
                                    "
                                    :statusList="reportSubStatuses"
                                    :selectedStatusNumber="
                                        props.row.subStatuses
                                    "
                                ></StatusBadge>
                                <div v-else>
                                    {{ "-" }}
                                </div>
                            </template>
                        </ek-table>
                        </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StatusBadge from "@global/components/StatusBadge";
import { dateManipulatoin } from "@core/mixins/ui/dateManipulatoin";

export default {
    mixins: [dateManipulatoin],
    computed: {
        ...mapGetters(["activityReportsList"]),
        ...mapState({
            accountStatus: (state) => state.users.accountStatus,
            isUserReportUsersTabActive: (state) =>
                state.reports.isUserReportUsersTabActive,
            isActivityReportUsersTabActive: (state) =>
                state.reports.isActivityReportUsersTabActive,
            activeUserTab: (state) => state.reports.activeUserTab,
            reportSubStatuses: (state) => state.reports.reportSubStatuses,
            subStatus: (state) => state.subscriptions.subStatus,
            activities: (state) => state.activities.activities,
            orderStatusList: (state) => state.reports.orderStatusList,
            commercialActivities: (state) =>
                state.activities.commercialActivities,
            cities: ({ locations }) => locations.cities,
        }),
    },
    components: {
        StatusBadge,
    },
    data: () => ({
        cols: [
            {
                label: "اسم الفعالية",
                field: "accountName",
            },

            {
                label: "النشاط التجاري",
                field: "commercialActivityName",
            },
            {
                label: "اسم المدينة",
                field: "cityName",
            },
            {
                label: "اسم البلد",
                field: "countryName",
            },
            {
                label: "البريد الالكتروني/رقم الموبايل",
                field: "userName",
            },
            {
                label: "تاريخ التسجيل",
                field: "dateCreate",
            },
            {
                label: "حالة الحساب",
                field: "accountStatuses",
            },
            {
                label: "المتابعين",
                field: "followers",
            },
            {
                label: "الزائرين",
                field: "visitors",
            },
            {
                label: "عدد التعليقات",
                field: "comments",
            },
            {
                label: "عدد الإشعارات",
                field: "notifications",
            },
            {
                label: "عدد الوظائف",
                field: "jobs",
            },
            {
                label: "عدد الوظائف التقدم عليها",
                field: "jobSubmiting",
            },
            {
                label: "عدد الاشتراكات",
                field: "sub",
            },
            {
                label: "تاريخ آخر اشتراك",
                field: "endSub",
            },
            {
                label: "حالة الاشتراك",
                field: "subStatuses",
            },
        ],
        statsDate: [],
    }),
    created() {
        if (!localStorage.getItem("activityReports")) {
            this.$router.push("/admin/reports");
        }

        this.statsData = JSON.parse(localStorage.getItem("activityReports"));

        setTimeout(() => {
            window.print();
            this.$router.push("/admin/reports");
        }, 1500);
    },
    beforeDestroy() {
        localStorage.removeItem("activityReports");
    },
    methods: {
        lastSubscriptionDate(startDate, endDate) {
            let sDate = startDate
                ? `${new Date(startDate).toLocaleDateString()}`
                : "";

            let eDate = endDate
                ? `${new Date(endDate).toLocaleDateString()}`
                : "";

            let fullDate = "";

            fullDate = sDate + "-" + eDate;

            return fullDate;
        },
        filterActivityReports(el) {
            let [subscriptionStartDate = null, subscriptionEndDate = null] =
                this.filter.subscriptionDate
                    ?.replace("to", "")
                    .replace(" ", "")
                    .split(" ");

            let [registerStartDate = null, registerEndDate = null] =
                this.filter.registerDate
                    ?.replace("to", "")
                    .replace(" ", "")
                    .split(" ");

            return (
                (el.followers == this.filter.followersCount ||
                    !this.filter.followersCount) &&
                (el.commercialActivityId == this.filter.commercialActivityId ||
                    !this.filter.commercialActivityId) &&
                (el.cityId == this.filter.cityId || !this.filter.cityId) &&
                (el.visitors == this.filter.visitorsCount ||
                    !this.filter.visitorsCount) &&
                (el.sub == this.filter.subscriptionsCount ||
                    !this.filter.subscriptionsCount) &&
                (el.subStatuses == this.filter.subscriptionStatusId ||
                    !this.filter.subscriptionStatusId) &&
                (el.accountStatuses == this.filter.accountStatus ||
                    !this.filter.accountStatus) &&
                ((this.setDateTime(new Date(subscriptionStartDate)) >=
                    this.setDateTime(new Date(el.startSub)) &&
                    this.setDateTime(new Date(subscriptionStartDate)) <=
                        this.setDateTime(new Date(el.endSub))) ||
                    (this.setDateTime(new Date(subscriptionEndDate)) >=
                        this.setDateTime(new Date(el.startSub)) &&
                        this.setDateTime(new Date(subscriptionEndDate)) <=
                            this.setDateTime(new Date(el.endSub))) ||
                    this.filter.subscriptionDate.length == 0) &&
                ((this.setDateTime(new Date(el.dateCreate)) >=
                    this.setDateTime(new Date(registerStartDate)) &&
                    this.setDateTime(new Date(el.dateCreate)) <=
                        this.setDateTime(new Date(registerEndDate))) ||
                    this.filter.registerDate.length == 0)
            );
        },
    },
};
</script>

<style lang="scss" scoped>
    
.activity-reports-table {
    &::v-deep {
        th,
        tr {
            font-size: 0.6rem;
        }
    }
}

.report-activity-filtering-area {
    align-items: center;

    .report-print-header {
        @media only screen and (max-width: 670px) {
            flex-direction: column;
            align-items: center;
        }
    }

    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }

    &__order-status-title {
        margin-top: 1.4rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__city-order-status-title {
        margin-top: 0.4rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__today-reports-title {
        font-size: 1.5rem;
    }

    &__filter-title {
        font-weight: bold;
        font-size: 1.2rem;

        color: #6e6b7b;
    }

    &__filter-container {
        display: flex;
        gap: 2rem;
    }

    &__filter-by-activity-container {
        display: flex;
    }

    &__activity-filter-title {
        margin-top: 1.4rem;
        font-size: 1.2rem;

        color: #6e6b7b;
    }

    &__activity-filter-input {
        flex: 1;
    }

    &__filter-by-reports-status-container {
        display: flex;
    }

    &__filter-by-order-date-container {
        margin-top: 0.6rem;
    }

    &__date-filter-by-order-date-container {
        margin-top: 2.2rem;
    }

    &__filter-by-report-status-container {
        margin-top: -0.5rem;
    }

    &__city-filter-by-report-status-container {
        margin-top: 0.3rem;
    }

    &__select-filter-by-report-status-container {
        margin-top: 0.3rem;
    }

    &__order-date-title {
        margin-top: 0.6rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__order-date-input {
        margin-top: -0.9rem;
        flex: 1;
    }

    &__order-status-type {
        margin-top: 1.3rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__order-status-input {
        flex: 1;
    }
}
</style>

<style lang="scss">
.removed {
    background: #ea545560 !important;
}
.input-group-append {
    .input-group-text {
        border-radius: 0 0.357rem 0.357rem 0 !important;
    }
}
.input-group-prepend {
    .input-group-text {
        border-radius: 0.357rem 0 0 0.357rem !important;
    }
}

@media print {
    // Global Styles
    body {
        background-color: transparent !important;
    }
    .card {
        page-break-after: always;
    }
    .page-header,
    .page-header-space {
        height: 130px;
    }

    .page-footer,
    .page-footer-space {
        height: 47px;
    }
    .card-header {
        z-index: 1;
        top: 16px;
        left: 12px;
        right: 12px;
    }
    footer.footer {
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
    }
    nav.header-navbar {
        display: none;
    }
    .main-menu {
        display: none;
    }
    .header-navbar-shadow {
        display: none !important;
    }
    .content.app-content {
        margin: 0 !important;
        padding-top: 2rem !important;
    }
    .card {
        background-color: transparent;
        box-shadow: none;
    }
    .input-group-text {
        border: none;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .customizer-toggle {
        display: none !important;
    }
    .content-header {
        display: none !important;
    }
    .vgt-table thead th {
        border-bottom: solid 2px rgba(0, 0, 0, 0.125) !important;
    }
    @page {
        margin: 0;
    }
}

.report-print-header {
    @media only screen and (max-width: 670px) {
        flex-direction: column;
        align-items: center;
    }
}

.filterd-date {
    font-size: 1.2rem;
}
</style>
