var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-reports-table"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"p-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0 position-fixed flex-column"},[_c('div',{staticClass:"d-flex w-100 justify-content-between report-print-header"},[_c('div',[_c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('h3',{staticClass:"text-primary pr-2 pl-2"},[_c('strong',[_vm._v("MeMate")])]),_c('img',{attrs:{"src":"/media/favicon.ico","width":"32","height":"32","alt":""}})])])]),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1),_c('b-card-body',{staticClass:"mt-2"},[_c('ek-table',{staticClass:"mt-1",attrs:{"items":_vm.statsData,"columns":_vm.cols,"no_delete":"","no_select":"","no_pagination":"","striped":""},scopedSlots:_vm._u([{key:"items.dateCreate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? new Date(value) .toLocaleString("en-UK") .split(",") .reverse() .join(" ") : "-")+" ")]}},{key:"items.accountStatuses",fn:function(ref){
var value = ref.value;
return [_c('StatusBadge',{attrs:{"statusList":_vm.accountStatus,"selectedStatusNumber":value}})]}},{key:"items.endSub",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(props.row.startSub && props.row.endSub ? _vm.lastSubscriptionDate( props.row.startSub, props.row.endSub ) : "-")+" ")]}},{key:"items.age",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? value : "")+" ")]}},{key:"items.subStatuses",fn:function(ref){
var props = ref.props;
return [(
                                    props.row.startSub && props.row.endSub
                                )?_c('StatusBadge',{attrs:{"statusList":_vm.reportSubStatuses,"selectedStatusNumber":props.row.subStatuses}}):_c('div',[_vm._v(" "+_vm._s("-")+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }